import { GatsbyImage } from "gatsby-plugin-image";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import BlockContent from "@sanity/block-content-to-react";
import React from "react";

import Share from "../share";
import { Link } from "gatsby";

const serializers = {
  types: {
    block: (props) => (
      <p className="text-sm mt-3 text-gray-500 leading-6">{props.children}</p>
    ),
  },
};

const PostContent = ({ post, location }) => {
  return (
    <div className="md:grid md:gap-4 md:grid-cols-3 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="col-span-2 py-12">
        <GatsbyImage
          className="border border-gray-100 rounded-lg shadow-md max-w-full"
          alt={post.name}
          image={post.mainImage.asset.gatsbyImageData}
        />
      </div>
      <div className="md:ml-8 py-12">
        <div className="md:sticky top-0 p-8 rounded-lg bg-slate-50">
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {post.name}
            </h2>
            <BlockContent blocks={post.description} serializers={serializers} />
            <a
              href={post.website}
              className="block mt-6 no-underline rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base text-white shadow-sm w-full text-center hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="flex items-center justify-center w-full">
                <ArrowTopRightOnSquareIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Visit Website
              </span>
            </a>
          </div>
          <div className="border-t border-gray-200 mt-6 py-6">
            <h3 className="mb-2 text-gray-500 text-sm">Share this website:</h3>
            <Share name={post.name} url={location.href} />
          </div>
          <div className="border-t border-gray-200 pt-6">
            <p className="text-gray-500 text-sm">
              Know a great looking site created using Tailwind CSS?{" "}
              <Link className="text-indigo-600 hover:underline" to="/submit">
                Submit it today
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostContent;
