import { graphql } from "gatsby";
import React from "react";

import Footer from "../components/footer";
import Header from "../components/header";
import HeadContent from "../components/head";
import Newsletter from "../components/newsletter";
import PostContent from "../components/post-content";

const Post = ({ data, location }) => {
  return (
    <>
      <Header />
      <PostContent post={data.sanityPost} location={location} />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Post;

export const Head = ({ data, location }) => (
  <HeadContent
    title={`${data.sanityPost.name} - Using Tailwind`}
    location={location}
    shareSrc={data?.sanityPost?.summaryImage?.asset?.url}
  />
);

export const pageQuery = graphql`
  query PostById($id: String!) {
    sanityPost(_id: { eq: $id }) {
      name
      website
      description: _rawDescription
      mainImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 900)
        }
      }
      summaryImage {
        asset {
          url
        }
      }
    }
  }
`;
